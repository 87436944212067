import $ from 'jquery'
export default class TurnSwitcher{
    state = {
        element: null,
        isActive: false,
        onActiveHandle: () => {},
        onDeactiveHandle: () => {}
    }
    constructor(options){
        this.state = {...this.state, ...options}
    }
    turn(){
        this.state.isActive = !this.state.isActive;
        if(this.state.isActive){
            $(this.state.element).closest(".turn-switcher").toggleClass("active");
            this.state.onActiveHandle();
        } else {
            $(this.state.element).closest(".turn-switcher").removeClass("active");
            this.state.onDeactiveHandle();
        }
    }
    init(){
        $(this.state.element).on("click", () => {
            this.turn();
        });
        return this;
    }
}