import 'window';
import Inputmask from "inputmask";
import 'jquery-validation';
import $ from 'jquery';
import { validation } from './rules'

export default class Feedback {
    static submit(form) {
        const data = $(form).serializeArray();
        console.log(data);
        let result = {};
        data.forEach(item => result[item.name] = item.value);
        $.post("/endpoints/mail.php", result).then((response) => {
            response = JSON.parse(response);
            if(response.status == "success"){
                jQuery.fancybox.open({
                    type: "inline", 
                    src: "#success-submit-popup"
                });
            }
        });
    }
    static showSidebar() {       
        setTimeout(() => {
            $(".float-sidebar").addClass("active");
            $("main").addClass("translate");
        }, 400);
        
    }
    static hideSidebar() { 
        $(".float-sidebar").removeClass("active");
        $("main").removeClass("translate");
    }
    static init(options) { 
        $(".js-feedback-show").on("click", () => options.turn.turn());
        $(".js-feedback-hide").on("click", () => options.turn.turn());
        Inputmask({mask: "+7 (999) 999-99-99"}).mask("input[name='phone']");
        const form = $("#feedback-form");
        const configRules = { ...validation, submitHandler: () => this.submit(form) }
        form.validate(configRules);
    }
}