import 'window'
export default class Popups{
    static init(){
        $(".js-video").on("click", function(){
            const url = $(this).attr("data-href");
            jQuery.fancybox.open({
                type: "youtube", 
                src: url
            });
        });
        
    }
}