import 'window'
import $ from 'jquery'
import Feedback from './Feedback'
import TurnSwitcher from './TurnSwitcher'
import SlideText from './SlideText'
import Popups from './Popups'
class App {
    static init() {
        const turn = new TurnSwitcher({
            element: $(".turn-switcher"),
            onActiveHandle: Feedback.showSidebar,
            onDeactiveHandle: Feedback.hideSidebar
        }).init();
        const slideText = new SlideText({
            element: $(".slide-text")
        }).init();
        console.log(slideText);
        window.slideText = slideText;
        Feedback.init({
            turn
        });
        Popups.init();
        console.log(turn);
    }
}
App.init();
export default App; 