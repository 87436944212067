const REQUIRED_FIELD = "Поле обязательно к заполнению!";
const WRONG_FORMAT = "Неверный формат";
const validation = {
    rules: {
        name: {
            required: true
        },
        email: {
            required: true,
            email: true
        },
        phone: {
            required: true
        }
    },
    messages: {
        name: {
            required: REQUIRED_FIELD
        },
        email: {
            required: REQUIRED_FIELD,
            email: WRONG_FORMAT
        },
        phone: {
            required: REQUIRED_FIELD,
            email: WRONG_FORMAT
        }
    },
    submitHandler: () => {
        alert();
        event.preventDefault();
    }
}
export { validation }