export default class SlideText{
    state = {
        element: null,
        list: [],
        currentIndex: 0
    }
    constructor(options){
        this.state = {...this.state, ...options}
    }
    tick(){
        const text = $(this.state.list[this.state.currentIndex]);
        text.addClass("in");
        setTimeout(() => {
            text.removeClass("in");
            text.addClass("out");
            setTimeout(() => {
                text.removeClass("out");
            }, 500);
        }, 2000);
        this.state.currentIndex = this.state.currentIndex < this.state.list.length - 1 ? this.state.currentIndex + 1 : 0;
        
    }
    init(){
        $(this.state.element).find(".slide-text__item").each((i, item) => this.state.list.push(item));
        setInterval(() => {
            this.tick(); 
        },2000);
        return this;
    }
}